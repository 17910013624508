import React, { useEffect } from "react"
import "./Schedule.scss"
import HeroBanner from "../../components/HeroBanner"
import Layout from "../../components/Layout"
import { Helmet } from "react-helmet"
import useGlobal from "../../util/GlobalHook"
import { graphql } from "gatsby"
import Image from "../../components/Image"

const Schedule = ({ data }) => {
  const [globalState, globalActions] = useGlobal()

  useEffect(() => {
    if (globalState.loadedHealCode) {
      window.location.reload()
    } else {
      globalActions.updateLoadedHealCode(true)
    }
  }, [])

  return (
    <Layout>
      <Helmet>
        <script
          src="https://widgets.healcode.com/javascripts/healcode.js"
          type="text/javascript"
        ></script>
      </Helmet>
      <div className="Schedule">
        <HeroBanner
          image={data.banner.childImageSharp.fluid}
          title="Our Schedule"
          caption="Go on. You'll thank yourself in the morning."
        />
        <div className="container content">
          <h2>Pandemic Workout</h2>
          <p>
            The gym is now your living room. Sign up for live online classes
            today. See schedule below.
          </p>
          <healcode-widget
            data-type="schedules"
            data-widget-partner="presenters"
            data-widget-id="4133775c50b"
            data-widget-version="0"
          ></healcode-widget>
        </div>
      </div>
    </Layout>
  )
}

export default Schedule

export const query = graphql`
  query {
    banner: file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    schedule: file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2020/01/schedule-2020.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
