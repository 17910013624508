import React from 'react';
import globalHook from 'use-global-hook';
 
const initialState = {
  loadedHealCode: false,
};
 
const actions = {
  updateLoadedHealCode: (store, value) => {
    store.setState({ loadedHealCode: value });
  },
};
 
export default globalHook(React, initialState, actions);